import Breadcrumb from '@/layouts/full/shared/breadcrumb/Breadcrumb'
import PageContainer from '../src/components/container/PageContainer'
import PeopleComponent from '../src/components/people/PeopleComponent'

export default function Index() {
  const BCrumb = [
    {
      title: 'Home',
    },
  ]

  return (
    <PageContainer>
      <Breadcrumb title="People" items={BCrumb} children={<></>} />
      <PeopleComponent />
    </PageContainer>
  )
}
